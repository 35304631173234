<template>
  <!-- 新 - 首页 - 商务合作 -->
  <div class="aboutUsNew gotop">
    <headPageNew activeName="5"></headPageNew>
    <div class="banner"></div>
    <div class="main main1">
      <h3>公司简介</h3>
      <div class="companyProfile">
        <div class="companyProfileInner">
          <img src="../../assets/loginIndexNew/aboutUsImg.png" alt="">
          <p>北京晟融信息技术有限公司是晟融数据（股票代码870733）的全资核心企业，依托数字经济新趋势，通过自主研发的数据资源体系建设与服务，致力于企业人力资源数字化升级转型服务。为政府提供职业技能、安全生产、职业健康的在线培训平台搭建、监管与服务。围绕职业化人群提供就业、社保、取证、培训等深度服务。获评北京市最具成长型软件企业。</p>
        </div>

      </div>
    </div>
    <div class="invitationBar">
      <div class="invitationBarInner">
        <div class="invitationBarItem">
          <h4>15</h4>
          <p>服务区域</p>
        </div>
        <div class="invitationBarItem">
          <h4>110<i>+</i></h4>
          <p>服务行业</p>
        </div>
        <div class="invitationBarItem">
          <h4>65000<i>+</i></h4>
          <p>服务企业</p>
        </div>
        <div class="invitationBarItem">
          <h4>2200<i>+</i></h4>
          <p>服务机构</p>
        </div>
        <div class="invitationBarItem">
          <h4>230w<i>+</i></h4>
          <p>服务人次</p>
        </div>
      </div>
    </div>
    <div class="main">
      <h3>业务架构</h3>
      <div class="businessArchitecture">
        <div class="businessArchitectureLine">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div class="businessArchitectureInner">
          <div class="businessArchitectureItem">
            <h4>数字赋能政府民生服务</h4>
            <p>基于大数据的软件服务，发挥数据平台建设和数据业务分析经验，搭建全过程数据资源体系，完成系列省级项目系统建设，推动数据与业务深度融合。</p>
          </div>
          <div class="businessArchitectureItem">
            <h4>机构数字化转型升级</h4>
            <p>提供平台+内容+运营服务，帮助机构招生、培训、考试等全流程数字升级，打造在线业务全闭环，助力职业教育发展。</p>
          </div>
          <div class="businessArchitectureItem">
            <h4>企业人力资源数字化赋能</h4>
            <p>基于大数据与互联网的技术能力和广泛的客户资源，赋能人力资源服务机构，为企业提供人力资源信息化、咨询、用工、培训等领域服务。</p>
          </div>
          <div class="businessArchitectureItem">
            <h4>全职涯专业技能提升</h4>
            <p>围绕职业化人群提供技能提升、取证考证、就业创业、社保康养等全过程的高价值服务。</p>
          </div>
        </div>
      </div>
    </div>

    <bottomPage />

    <hz/>
  </div>
</template>

<script>
import headPageNew from "../wrongAuthenticatePage/headPageNew.vue";
import bottomPage from "@/views/wrongAuthenticatePage/bottomPage";

import hz from "../wrongAuthenticatePage/hz.vue";
export default {
  name: "aboutUsNew",
  components: {
    headPageNew,
    bottomPage,

    hz
  },
  data() {
    return {
    }
  },
  methods:{
  }
}
</script>

<style lang="less" scoped>
.aboutUsNew{
  margin-top: 62px;
  background-color: #F9FAFC;
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar { width: 0 !important }
}

.banner{
  height: 300px;
  background-size: cover;
  background-position: center;
  background-image: url("../../assets/loginIndexNew/banner4.png");
}
.main{
  position: relative;
  width: 1440px;
  margin: 0 auto;
  h3{
    position: relative;
    text-align: center;
    padding: 40px 0 40px;
    color: #333;
    font-size: 30px;
    font-weight: bold;
  }
}
.main1{
  &:before{
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    height: 100%;
    background: #FFF;
  }
}
.companyProfile{
  padding-bottom: 60px;
}
.companyProfileInner{
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F9FAFC;
  border-radius: 12px;
  line-height: 1.5;
  img{
    width: 824px!important;
    height: 300px!important;
    border-radius: 12px;
  }
  p{
    font-size: 16px;
    color: #666;
    margin: 0 40px;
  }
}
.businessArchitecture{
  margin-bottom: 60px;
}
.businessArchitectureLine{
  width: 75%;
  margin: 0 auto;
  display: flex;
  height: 60px;
  &>div{
    flex: 1;
    border: 1px solid #ddd;
    border-bottom: 0;
    &:nth-child(1){
      border-right: 0;
    }
    &:nth-child(3){
      border-left: 0;
    }
  }
}
.businessArchitectureInner{
  display: flex;
  justify-content: center;
  .businessArchitectureItem{
    flex: 1;
    background: #fff;
    transition: 0.5s;
    border-radius: 12px;
    margin-right: 30px;
    padding: 0 25px;
    &:last-child{
      margin-right: 0;
    }
    &:hover{
      box-shadow: 0px 10px 30px 1px rgba(224,228,238,0.5);
    }
    h4{
      text-align: center;
      margin: 69px 0 30px;
      font-size: 20px;
      color: #333;
    }
    p{
      text-align: left;
      font-size: 16px;
      line-height: 1.4;
      color: #666;
      margin-bottom: 69px;
    }
  }

}
.invitationBar{
  text-align: center;
  background-image: url("../../assets/loginIndexNew/blueBgImg.png");
  background-position: center;
  background-size: cover;
  height: 260px;
  .invitationBarInner{
    width: 1440px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .invitationBarItem{
      position: relative;
      flex: 1;
      color: #FFF;
      &:last-child{
        &:before{
          background: transparent;
        }
      }
      &:before{
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: 1px;
        height: 79px;
        background: #FFF;
        opacity: 0.5;
      }
      h4{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        font-size: 36px;
        margin-bottom: 10px;
        font-weight: 500;
        i{
          font-size: 16px;
          font-weight: normal;
        }
      }
      p{
        font-size: 20px;
      }
    }
  }

}
@media (max-width: 1500px) {
  .main{
    width: 1200px;
  }
  .banner{
    height: 250px;
  }
  .invitationBar .invitationBarInner{
    width: 1200px;
  }
  .companyProfileInner img{
    width: 687px!important;
    height: 250px!important;
  }
}
.clearfix:after{
  content:"";//设置内容为空
  height:0;//高度为0
  line-height:0;//行高为0
  display:block;//将文本转为块级元素
  visibility:hidden;//将元素隐藏
  clear:both;//清除浮动
}
.clearfix{
  zoom:1;//为了兼容IE
}
</style>